import { jwtDecode } from "helpers";
import { SessionData, ParsedToken } from "types";

export const setSession = (data: SessionData) => {
  if (typeof window !== "undefined") {
    return sessionStorage.setItem("session", JSON.stringify(data));
  }
  return null;
};

export const getSession = (): SessionData | null => {
  if (typeof window !== "undefined") {
    const session = sessionStorage.getItem("session");
    if (session) {
      return JSON.parse(session) as SessionData;
    }
  }
  return null;
};

export const removeSession = () => {
  sessionStorage.removeItem("session");
  sessionStorage.removeItem("entity");
};

export const isExpiredToken = () => {
  const token = getSession() as SessionData;
  if (!token) return true;

  const tokenData = jwtDecode(token.access_token) as ParsedToken;

  if (tokenData.exp) {
    const now = Date.now();

    return now > tokenData.exp * 1000;
  }

  return true;
};

export const getUser = () => {
  const session = getSession() as SessionData;

  if (!session || !session.access_token) {
    return;
  }
  const tokenData = jwtDecode(session?.access_token) as ParsedToken;
  return tokenData.sub || tokenData.username;
};
