import { Buffer } from "buffer";

export const randomNum = (n: number) => {
  const min = Math.pow(10, n - 1);
  const max = Math.pow(10, n) - 1;
  let randomNumber;
    const randomBytes = new Uint32Array(1);
    do {
      window.crypto.getRandomValues(randomBytes);
      randomNumber = randomBytes[0] / 0xffffffff;
    } while (randomNumber === 1);

  const scaledNumber = Math.floor(randomNumber * (max - min + 1)) + min;

  if (scaledNumber < min * 10) {
    return scaledNumber + min * 10;
  } else {
    return scaledNumber;
  }
};

export const objectToQueryString = (object: Record<string, any>) => {
  let newSearchParams = new URLSearchParams();
  for (let key in object) {
    newSearchParams.append(key, object[key]);
  }
  return `?${newSearchParams.toString()}`;
};

export const jwtDecode = (token: string): Record<string, any> => {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
};

export const round = (value: string | number) => {
  return Number(value).toFixed(2);
};

export const getEnvVarsStorage = (): Record<string, any> | null => {
  if (typeof window !== "undefined") {
    const session = sessionStorage.getItem("env");
    if (session) {
      return JSON.parse(session);
    }
  }
  return null;
};

export const setEnvVarsStorage = (data: Record<string, any>) => {
  if (typeof window !== "undefined") {
    return sessionStorage.setItem("env", JSON.stringify(data));
  }
  return null;
};