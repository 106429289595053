import React, { createContext, PropsWithChildren, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import { getSession, removeSession, setSession } from "helpers/sessionToken";
import { axiosRequests } from "requests";
import { IAxiosBaseContext, AppRoutes } from "types";

const AxiosBaseContext = createContext<IAxiosBaseContext | undefined>(undefined);

export const AxiosInstanceProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const axiosInstance = axios.create();

  const requests = axiosRequests(axiosInstance);

  axiosInstance.interceptors.request.use(
    config => {
      const session = getSession();
      if (session?.access_token) {
        // TODO: Remove ts-ignore
        // @ts-ignore
        config.headers.setAuthorization(`Bearer ${session.access_token}`);
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  function processQueue(error: any, token = "") {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };


  let refreshTokenLoading = false;
  let failedQueue: any[] = [];

  const onError = async (err: any) => {
    const originalConfig = err.config;
    const session = getSession();

    if ((err.response?.status === 401) && !originalConfig._retry && session?.refresh_token) {
      if (refreshTokenLoading) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalConfig.headers["Authorization"] = `Bearer ${token}`;
            return axiosInstance(originalConfig);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalConfig._retry = true;
      refreshTokenLoading = true;

      return new Promise(function (resolve, reject) {
        requests
          .refreshToken(session?.refresh_token ?? "")
          .then(data => {
            setSession({ ...data, timestamp: new Date().getTime() });
            originalConfig.headers["Authorization"] = `Bearer ${data.access_token}`;
            axiosInstance.defaults.headers.common.authorization = `Bearer ${data.access_token}`;
            processQueue(null, data?.access_token);
            resolve(axiosInstance(originalConfig));
          })
          .catch(err => {
            processQueue(err, "");
            removeSession();
            client.clear();
            navigate(AppRoutes.LOGIN);
            reject(err);
          })
          .then(() => {
            refreshTokenLoading = false;
          });
      });
    }

    if (err?.response?.status === 403) {
      return Promise.reject(err);
    }

    const showErrorMessage = 
      !err.response?.data?.hideDefaultMessage && !(err?.config?.url || "")?.includes("aspsp/reload");
    
    if (showErrorMessage) {
      // Custom error message
      if (err.response?.data?.showMessage && err.response?.data?.message && typeof err.response?.data?.message === 'string') {
         return err.response.data.message.charAt(0).toUpperCase() + err.response.data.message.slice(1);
      }
    }
    return Promise.reject(err);
  }

  axiosInstance.interceptors.response.use(response => { return response; }, onError,);

  return <AxiosBaseContext.Provider value={requests}>{children}</AxiosBaseContext.Provider>;
};

export const useGetAxiosRequests = () => {
  const context = useContext(AxiosBaseContext);
  if (!context) {
    throw new Error("you dont have axios requests");
  }
  return context;
};