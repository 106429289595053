import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Container } from "@mui/material";

import { UserAvatarWithMenu, LocaleSwitcher } from "components/common";
import { getUser } from "helpers/sessionToken";
import { getEnvVarsStorage } from "helpers";
import { isConsumerBank } from "constants/globals";
import { appBar, toolBar, appLogo, link, consumerAppLogo, appLogoReliance } from "./styles";
import { AppRoutes } from "types";
import { handleLogo } from "../config";
import tenant1Logo from 'assets/images/devopsBankLogo.png';
import tenant2Logo from 'assets/images/obie2BankLogo.png';

const Header: React.FC = () => {
  const user = getUser();
  const envs = getEnvVarsStorage();

  const isConsumer = isConsumerBank(envs?.tenantName);

  return (
    <AppBar position="static" sx={appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={toolBar}>
          <Link to={AppRoutes.HOME} style={link}>
            {envs?.logo && (
              envs?.tenantName == 'devopsbank' ? (
                <img src={tenant1Logo}  style={appLogo}/>
              ) : envs?.tenantName == 'obie2-bank' ? (
                <img src={tenant2Logo} style={appLogo}/>
              ) : envs?.tenantName?.startsWith("reliancebank") ? (
                 <img src={handleLogo(envs?.logo) ?? ""} alt="logo" style={isConsumer ? consumerAppLogo : appLogoReliance} />
              ) : ( <img src={handleLogo(envs?.logo) ?? ""} alt="logo" style={isConsumer ? consumerAppLogo : appLogo} />)
            )} 
          </Link>
          <div>
            <LocaleSwitcher />
            {user && <UserAvatarWithMenu />}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
