import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import { alpha, Box, Container, Stack, styled, Typography, Link as MUILink } from "@mui/material";

import RelianceTermsAndConditions from 'assets/files/Reliance-Bank-Terms-and-Conditions.pdf';

import { copyrightWrapper, flexContainer } from "./styles";
import { AppRoutes } from "types";
import { getEnvVarsStorage } from "helpers";
import { isReliance } from "constants/globals";

const FooterWrapper = styled("footer")(({ theme }) => ({
  borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
  backgroundColor: theme.palette.common.white,
}));

interface FooterProps {
  isAdditionalLinks?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isAdditionalLinks = true }) => {
  const { t } = useTranslation("common");

  const envs = getEnvVarsStorage();

  return (
    <FooterWrapper>
      <Box sx={copyrightWrapper}>
        <Container sx={flexContainer}>
          <Typography color="text.primary">
            &copy; {format(new Date(), "yyyy")}. {t("footer.copyright")}
          </Typography>

          {isAdditionalLinks && (
            <Stack direction={{ xs: "column", sm: "row" }}>
               {
                  isReliance(envs?.tenantName) ?
                  <><a style={{ color: "#0E1210", textDecoration: "unset" }} href={RelianceTermsAndConditions} download>{t("nav.termsConditions")}</a> &nbsp;&nbsp;&nbsp;&nbsp;</>
                :
                <MUILink component={Link} to={AppRoutes.TERMS} underline="none" color="text.primary" sx={{ mr: "24px" }}>
                  {t("nav.termsConditions")}
                </MUILink>
              }
              
              <MUILink component={Link} to={AppRoutes.PRIVACY} underline="none" color="text.primary">
                {t("nav.privacyPolicy")}
              </MUILink>
            </Stack>
          )}
        </Container>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
